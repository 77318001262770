import React, { useEffect } from "react"
import { graphql, Link } from "gatsby"

import { GatsbyImage } from "gatsby-plugin-image";
import Layout from '../components/layout'
import Breadcrumb from "../components/Global/Breadcrumb"
import "../css/SponsorsTemplate.css";


function SponsorsTemplate(props) {
    console.log(props)
    let data = props.data;

    console.log(data)

    function getBreadcrumb() {
        let bCrumb = [];
        bCrumb.push({
            text: "Home",
            url: `${props.pageContext.language !== "EL" ? "/" + props.pageContext.language.toLowerCase() : "/"}`
        })
        bCrumb.push({
            text: data.allWpPage.nodes[0].breadcrumbAcf.breadcrumbName,
        })
        return bCrumb;
    }

    function constructMetaData(page, currentPage, language) {
        let payload = {
            title: page.seo.title,
            description: page.seo.metaDesc,
            canonical: `https://dkaa.gr${currentPage}`,
            fbTitle: page.seo.opengraphTitle ? page.seo.opengraphTitle : page.seo.title,
            fbDescription: page.seo.opengraphDescription ? page.seo.opengraphDescription : page.seo.metaDesc,
            fbUrl: `https://dkaa.gr${currentPage}`,
            fbType: "website",
            locale: language.toLowerCase(),
            image: page.seo.opengraphImage ? page.seo.opengraphImage.sourceUrl : ""
        }
        return payload
    }

    function getWidth(i) {
        if (i === 0) {
            return "w-full md:w-1/4 sp-url flex"
        } else if (i === 1 || i == 2) {
            return "w-full md:w-1/6 sp-url p-4 flex"
        } else {
            return "w-full sp-url"
        }
    }

    return (
        <div>
            <Layout
                headerMenu={props.pageContext.headerMenu}
                footerMenu={props.pageContext.footerMenu}
                recentPosts={data.allWpPost}
                currentPage={{ currentPage: props.pageContext.currentPage, language: props.pageContext.languageName }}
                availableVersions={props.pageContext.availablePages}
                metaData={constructMetaData(data.allWpPage.nodes[0], props.pageContext.currentPage, props.pageContext.language)}
                services={props.pageContext.servicesCategories}
                preHeader={data.allWpTemplate.nodes[0].contactInformationTemplate}
            >
                <div>

                    <section>
                        <Breadcrumb list={getBreadcrumb()} h1={data.allWpPage.nodes[0].breadcrumbAcf.breadcrumbName} />
                    </section>

                    <section>
                        <div className="container-cs m-auto py-16">
                            {data.allWpPage.nodes[0].sponsorsAcf.sponsors && data.allWpPage.nodes[0].sponsorsAcf.sponsors.map((sponsors, i) => (
                                <div key={`sponsors-${i}`} className="mb-8">
                                    <h2 className="sp-h2" style={{ color: sponsors.color, borderBottom: `4px solid ${sponsors.color}` }}>{sponsors.title}</h2>
                                    <div className="flex flex-wrap justify-center">
                                        {sponsors.sponsorList && sponsors.sponsorList.map((sp, y) => (
                                            <a href={sp.url} className={getWidth(i)}>
                                                <GatsbyImage
                                                    image={sp.image.localFile.childImageSharp.gatsbyImageData}
                                                    alt={sp.image.altText}
                                                    className="m-auto sc-m-img"
                                                />
                                            </a>
                                        ))}
                                    </div>
                                </div>
                            ))}
                            <div className="mb-8">
                                <h2 className="sp-h2" style={{ color: "#333333", borderBottom: `4px solid #333333` }}>{data.allWpPage.nodes[0].sponsorsAcf.sponsorsImage.title}</h2>
                                <div className="w-full">
                                    <GatsbyImage
                                        image={data.allWpPage.nodes[0].sponsorsAcf.sponsorsImage.image.localFile.childImageSharp.gatsbyImageData}
                                        alt={data.allWpPage.nodes[0].sponsorsAcf.sponsorsImage.image.altText}
                                        className="m-auto sc-m-img"
                                    />
                                </div>
                            </div>
                        </div>
                    </section>

                </div>
            </Layout>
        </div>
    );
}

export default SponsorsTemplate

// export const pageQuery = graphql`query GET_SPONSORS($id: ID!, $language: WPGraphQL_LanguageCodeFilterEnum, $themeTemplateUri: ID!) {
//   wpgraphql {
//     page(id: $id) {
//       seo {
//         canonical
//         title
//         metaDesc
//         opengraphDescription
//         opengraphTitle
//         opengraphImage {
//           sourceUrl
//         }
//       }
//       content
//       breadcrumbAcf {
//         breadcrumbName
//         image {
//           sourceUrl
//           altText
//           localFile {
//             childImageSharp {
//               gatsbyImageData(placeholder: NONE, layout: FULL_WIDTH, quality: 100)
//             }
//           }
//         }
//       }

//       sponsorsAcf {
//         sponsors{
//           title
//           color
//           sponsorList{
//             url
//             image{
//                 altText
//                 sourceUrl
//                 localFile {
//                     childImageSharp {
//                         gatsbyImageData(width: 262, height:262, placeholder: NONE, layout: CONSTRAINED, quality:100)
//                     }
//                 }
//             }
//           }
//         }
//         sponsorsImage{
//             image{
//                 altText
//                 sourceUrl
//                 localFile {
//                     childImageSharp {
//                         gatsbyImageData(placeholder: NONE, layout: FULL_WIDTH, quality: 100)
//                     }
//                 }
//             }
//             title
//         }
//       }



//     }

//     template(id: $themeTemplateUri, idType: SLUG) {
//       contactInformationTemplate {
//         telephoneNumber
//         supportEmail
//         hoursLabel
//         hoursTitle
//         form {
//           title
//           subTitle
//           fullName
//           email {
//             label
//             errorMessage
//           }
//           subject
//           message {
//             label
//             errorMessage
//           }
//           telephone {
//             label
//             errorMessage
//           }
//           sendButton
//         }
//         responseMessages {
//           success
//           failed
//         }
//       }
//     }
//     posts(
//       first: 2
//       where: {language: $language, orderby: {field: DATE, order: DESC}}
//     ) {
//       ...RecentPostsFragment
//     }
//   }
// }
// `

export const pageQuery = graphql`query GET_SPONSORS($id: String, $language: WpLanguageCodeEnum, $themeTemplateUri: String) {
    allWpPage(filter:{id: {eq: $id} ,language: {code: {eq: $language}}}) {
        nodes {

      seo {
        canonical
        title
        metaDesc
        opengraphDescription
        opengraphTitle
        opengraphImage {
          sourceUrl
        }
      }
    content
    breadcrumbAcf {
         breadcrumbName
        
       }
      
       sponsorsAcf {
        sponsors{
          title
          color
          sponsorList{
            url
            image{
                altText
                sourceUrl
                localFile {
                    childImageSharp {
                        gatsbyImageData(width: 262, height:262, placeholder: NONE, layout: CONSTRAINED, quality:100)
                    }
                }
            }
          }
        }
        sponsorsImage{
            image{
                altText
                sourceUrl
                localFile {
                    childImageSharp {
                        gatsbyImageData(placeholder: NONE, layout: FULL_WIDTH, quality: 100)
                    }
                }
            }
            title
        }
      }

    }

    }

    allWpTemplate(filter:{ slug:{eq: $themeTemplateUri} }) {
        nodes{
      contactInformationTemplate {
        telephoneNumber
        supportEmail
        hoursLabel
         address {
           addressGoogleLink
          addressLabel
         }
        hoursTitle
        form {
          title
          subTitle
          fullName
          email {
            label
            errorMessage
          }
          subject
          message {
            label
            errorMessage
          }
          telephone {
            label
            errorMessage
          }
          sendButton
        }
        responseMessages {
          success
          failed
        }
      }
    }
    }
    allWpPost(
      limit: 2
      filter: {language: {code: {eq: $language}}}
    ) {
      ...RecentPostsFragment
    }
}
`

